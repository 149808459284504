export default {
    setMessages(state, messages) {

        state.messages = messages
    },
    setEpisodeId() {
    },
    closeMessagePanel(state) {
        state.panelState = false;
    },
    openMessagePanel(state) {
        state.panelState = true;
    },

    setMessageEpisodeId(state, episodeId) {
        state.episodeId = episodeId;
    },

    unloadMessages(state) {
        state.sendAssessments = false;
        state.sendObservations = false;
        state.sendInterventions = false;
        state.messages = [];
    },

    pushMessage(state, message) {
        state.messages.push(message);
    },
    setLoadingState(state, status) {

        state.messagesLoading = status
    },

    setSendAssessments(state, status) {

        state.sendAssessments = status
    },
    setSendObservations(state, status) {

        state.sendObservations = status
    },
    setSendInterventions(state, status) {

        state.sendInterventions = status
    },

    ADD_MESSAGES(state, messages) {

        state.messages.push(messages)
    },

    ADD_MESSAGES_TO_MESSAGES(state, messages) {

        state.messages.push(...messages)
    },

    SET_CURRENT_PAGE(state, page) {

        state.page = page
    },

    SET_LAST_PAGE(state, page) {

        state.last_page = page
    }
};
