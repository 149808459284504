export default {
  messages(state) {
    // return state.messages

    const messageList = [...state.messages]

    return messageList.sort((a, b) => {

      if(a.created_at < b.created_at) {

        return -1
      }

      if(a.created_at > b.created_at) {

        return 1
      }

      return 0
    })
  },

  isOpen(state) {
    return state.isOpen;
  },

  getEpisodeId(state) {
    return state.episodeId;
  },
  panelState(state) {
    return state.panelState;
  },

  isLoading(state) {

    return state.messagesLoading
  },

  getSendAssessments(state) {

    return state.sendAssessments
  },

  getSendObservations(state) {

    return state.sendObservations
  },

  getSendInterventions(state) {

    return state.sendInterventions
  },
};
